import React from "react"
import Top from "../pageComponents/Top"

import Layout from "../components/layout"
import SEO from "../components/seo"

export interface PageProp {
  location: Location
}

const IndexPage: React.FC<PageProp> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO
        title="VantagePoint | Asia's Premier Executive Search"
        description="Vantage Point assists professionals and businesses achieve strategic, long term, organizational growth"
      />
      <Top />
    </Layout>
  )
}

export default IndexPage
